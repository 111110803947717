



// OLD Remplacé par Search Tableau








import _ from 'lodash'
import moment from 'moment'
import axios from '../common/axios.js'

import MenuSousMenu from '../common/ui/menu_sous-menu.vue'

import Select from '../common/forms/Select.vue'

export default {
	data() {
		return {
			show:'all'
		}
	},
	created(){
		
	},
	methods: {
		get_date(recherche){
			if (recherche) {
				moment.locale(this.$store.state.ui.langue.substr(0, 2));
				return moment(String(recherche.modified)).format('D MMMM YYYY H:mm')
			}
			return '';
		},
		setRecherche(recherche){
			this.$store.commit('UI_SET_RECHERCHE_ID', recherche.nom)
			this.$store.dispatch('setSaveSearch', recherche)
		},
		deleteRecherche(recherche){
			let self = this;
			this.$dialog.confirm(this.$t('filter_fav_confirm'), {loader: true})
			.then(dialog => {
				//AS--> Demande la suppression du favoris en DB
				axios.post('api=webinex/deleteUserSearch', recherche)
				.then(function (response) {
					
					//AS--> Ferme l'ancienne boîte de dialogue
					dialog.close();

					//RZ--> Recharge les recherches dans le stores
					axios.get('api=inex/users/{user_email}/views/{view_code}/searches/').then(function (response) {
						self.$store.commit('USER_SET_RECHERCHES', response.data)
					})

					//AS--> En cas de succès, supprime la valeur en FO et ferme la modale
					if(response.data == 'Search deleted' || response.data == '"Search deleted"'){

						//AS--> Récupère toutes les recherches en mémoire
						let recherches = _.clone(self.$store.state.user.recherches);

						//AS--> Supprime de recherches celui dont l'id correspond avec celui qu'il faut supprimer
						_.remove(recherches, function(r) {return r.nom == recherche.nom;});

						//AS--> Met à jour le store avec la nouvelle version
						self.$store.commit('USER_SET_RECHERCHES', recherches)
						
					//AS--> Sinon on prévient de l'impossibilité de supprimer le favoris
					}else{
						self.$dialog.alert(this.$t('filter_fav_echec'));
					}
				}).catch(function (error) {})

			});
		},
		downloadRecherche(recherche){
			this.$store.dispatch('open_modal', 'export_list')
			this.$store.commit('UI_SET_EXPORT_ID', recherche.id)
		},
		unsetRechercheDefaut(recherche){
			let self = this

			recherche.pardefaut = false
			recherche.update    = true

			//AS--> Demande la suppression du favoris en DB
			axios.post('api=inex/users/{user_email}/views/{view_code}/searches/', recherche)
			.then(function (response) {

				//AS--> En cas de succès, met à la jour la donnée locale
				if(response.data == 'Search updated' || response.data == '"Search updated"'){

					//AS--> Récupère toutes les recherches en mémoire
					let recherches = _.clone(self.$store.state.user.recherches);

					//AS--> Supprime de recherches celui dont le nom correspond avec celui qu'il faut supprimer
					recherches.forEach(function(rech){
						if(rech.nom == recherche.nom) rech.pardefaut = 0
					})

					//AS--> Met à jour le store avec la nouvelle version
					self.$store.commit('USER_SET_RECHERCHES', recherches)
					
				}
			}).catch(function (error) {})
		},
		setRechercheDefaut(recherche){
			let self = this

			recherche.pardefaut = true
			recherche.update    = true

			//AS--> Demande la suppression du favoris en DB
			axios.post('api=inex/users/{user_email}/views/{view_code}/searches/', recherche)

			//AS--> Récupère toutes les recherches en mémoire
			let recherches = _.clone(self.$store.state.user.recherches);

			//AS--> Modifie leur statut de recherche par défaut
			recherches.forEach(function(rech){
				rech.pardefaut = rech.nom == recherche.nom ? 1 : 0
			})

			//AS--> Met à jour le store avec la nouvelle version
			self.$store.commit('USER_SET_RECHERCHES', recherches)
		},
		getRechercheById(recherche_nom){
			let recherche = null
			this.$store.state.user.recherches.forEach(function(_recherche){
				if(_recherche.nom && _recherche.nom == recherche_nom) recherche = _recherche
			})
			return recherche
		},

		exportXls(context){
			this.$store.commit('UI_SET_EXPORT_ID', context.id)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'csv')
			this.$store.dispatch('open_modal', 'export_list')
		},

		exportCrm(context){
			this.$store.commit('UI_SET_EXPORT_ID', context.id)
			this.$store.commit('UI_SET_EXPORT_TYPE', 'crm')
			this.$store.dispatch('open_modal', 'export_list')
		}
	},
	computed:{
		getDefaut(){
			let self = this
			let defaut = null
			this.$store.state.user.recherches.forEach(function(recherche){
				if(recherche.pardefaut && (self.show == 'all' || self.show == self.$store.state.user.user.email) && recherche.email == self.$store.state.user.user.email) defaut = recherche
			})
			return defaut
		},
		getPasDefaut(){
			let self = this
			let recherches = []
			this.$store.state.user.recherches.forEach(function(recherche){
				if(self.show == 'all' || self.show == recherche.email){
					if((!recherche.pardefaut || recherche.email != self.$store.state.user.user.email) && recherche.nom != '__last__') recherches.push(recherche)
				}
			})
			recherches = _.orderBy(recherches, ['modified'], ['desc']);
			return recherches
		},
		menu_telecharger(){
			let menu = {
				menu: {
					picto: 'Download2',
					label: false
				},
				sousmenu: {
					list: []
				}
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCSV){
				menu.sousmenu.list.push({
					picto: 'Xls',
					label: this.$t('statut_bar_export'),
					action: this.exportXls,
					width: 18,
					height: 18
				})
			}

			//AS--> Export en Xls
			if(this.$store.getters.canExportCRM){
				menu.sousmenu.list.push({
					picto: 'Crm',
					label: this.$t('statut_bar_export_crm'),
					action: this.exportCrm,
					viewBox: '0 0 17 13',
					width: 17,
					height: 13
				})
			}

			return menu
		},
		utilisateurs(){
			let utilisateurs = []
			this.$store.state.user.recherches.forEach(function(recherche){
				utilisateurs.push(recherche.email)
			})
			//AS--> On supprime les doublons et on trie
			return [...new Set(utilisateurs)].sort();
		},
		utilisateurs_select(){
			let utilisateurs = [{ label: this.$t('search_users_select_default_value'), value:'all'}]
			if(this.utilisateurs && this.utilisateurs.length){
				this.utilisateurs.forEach(function(utilisateur){
					utilisateurs.push({
						label: utilisateur,
						value: utilisateur
					})
				})
			}
			return utilisateurs
		}
	},
	components: {
		SousMenu: () => import('./sous_menu.vue'),
		MenuSousMenu,
		Select
	}
}